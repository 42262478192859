/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import KPICards from "../common/ProjectKPIcards";
import { DatePicker } from 'antd';
import _ from "lodash";
import KPIChart from '../common/KPIChart'
import KPIChartSales from '../common/KPIChartSales'
import KPIChartPercentage from './KPIChartPercentage'
import 'react-nice-dates/build/style.css'
import { Spin, Button, Divider } from 'antd';
import DrawerSettings from "./KPIDrawerSettings"
import KPIButton from './KPIFileUpload'
import Gauge from "../common/KPIGauge"

const { RangePicker } = DatePicker;


const KPIDashboard = (props) => {
    const params = useParams();
    const _projectID = props.projectID;
    const [projectDateFormat, setProjectDateFormat] = useState("")
    const [loadingSpinner, setLoadingSpinner] = useState(false)
    const [ChartData, setChartData] = useState({});
    const [dateRange, setDateRange] = useState([]);
    const [defaultRange, setDefaultRange] = useState([]);
    const [chartTitle, setChartTitle] = useState("")
    const [drawerSettingsvisible, setDrawerSettingsvisible] = useState(false);
    const [databaseloading, setDatabaseLoading] = useState(false)
    const [kpisCalculations, setKpisCalculations] = useState({
        KeyToKey: 0,
        TotalSales: 0,
        ThroughPut: 0,
        PartsSales: 0,
        PartsProfit: 0,
        AverageCosumables: 0,
        AverageCosumablesPercentage: '0%',
        AveragePaintCost: 0,
        AveragePaintCostPercentage: 0,
        AverageSubletSales: 0,
        AverageSubletSalesPercentage: '0%',
        AverageSubletCost: 0,
        AverageSubletCostPercentage: '0%',
        AverageRepair: 0
    })
    const [kpiDataLimits,setKpiDataLimits] = useState({
        KeyToKeyDays: 0,
    TotalSales: 0,
    Throughput: 0,
    PartsSales: 0,
    PartsProfit: 0,
    PaintCost: 0,
      })

    const currencyNumberFormat = (value) => {
        if (value === undefined || value === null) {
            value = 0
        }
        return new Intl.NumberFormat(props.userLocale, {
            style: 'currency',
            currency: props.currencyName,
            currencyDisplay: "symbol"
        }).format(value);
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    };

    const handleClickLimits = () => {
        setDrawerSettingsvisible(true);
      };

      const getKpiLimits = () => {
        axios.get(`/api/getLimits/${_projectID}`,config).then((kpiresponse) => {
         if(kpiresponse.data){
         setKpiDataLimits(prevState => Object.assign({}, prevState, {
            KeyToKeyDays: kpiresponse.data.KeyToKeyDays,
            TotalSales: kpiresponse.data.TotalSales,
            Throughput: kpiresponse.data.Throughput,
            PartsSales: kpiresponse.data.PartsSales,
            PartsProfit: kpiresponse.data.PartsProfit,
            PaintCost: kpiresponse.data.PaintCost
         }))
       }
     })
       }

    //    useEffect(() => {
    //     kpiGetDates();
    //     CallDataForChart("Total Sales")
    //   }, []);

    useEffect(() => {
        if (dateRange.length >= 1) {
            setLoadingSpinner(true);
            getKpiLimits();
            kpicalculationsbyID(moment.utc(dateRange[0]).format(), moment.utc(dateRange[1]).format())
            CallDataForChart("Total Sales")
        }
    }, [dateRange])

    const kpiGetDates = () => {
        axios.post("/kpi/v2/getDates/" + _projectID, {}, config).then(datesResult => {
            console.log("datesResult")
            console.log(datesResult)
            console.log(datesResult.data.startDate)
            console.log(datesResult.data.endDate)
            setDateRange([moment(datesResult.data.startDate),moment(datesResult.data.endDate)])
        })
    }

    const kpicalculationsbyID = (dateRange1, dateRange2) => {
        axios.post("/kpi/v2/calculations/" + _projectID, {
            dateRange: [dateRange1, dateRange2]
        }, config).then(respData => {
            if (respData.data) {
                setKpisCalculations(prevState => Object.assign({}, prevState, {
                    KeyToKey: respData.data.KeyToKey || 0,
                    TotalSales: respData.data.TotalSales || 0,
                    ThroughPut: respData.data.ThroughPut || 0,
                    PartsSales: respData.data.PartsSales || 0,
                    PartsProfit: respData.data.PartsProfit || 0,
                    AverageCosumables: respData.data.AverageCosumables || 0,
                    AverageCosumablesPercentage: respData.data.AverageCosumablesPercentage || "0%",
                    AveragePaintCost: respData.data.AveragePaintCost || 0,
                    AveragePaintCostPercentage: respData.data.AveragePaintCostPercentage || 0,
                    AverageSubletSales: respData.data.AverageSubletSales || 0,
                    AverageSubletSalesPercentage: respData.data.AverageSubletSalesPercentage || "0%",
                    AverageSubletCost: respData.data.AverageSubletCost || 0,
                    AverageSubletCostPercentage: respData.data.AverageSubletCostPercentage || "0%",
                    AverageRepair: respData.data.AverageRepair || 0
                }))
                setLoadingSpinner(false);
            } else {
                setLoadingSpinner(false);
            }
        })
    }


    const disabledDate = (current) => {
        const currentYear = new Date().getFullYear();
        const year = current.year();
        return year !== currentYear;
    };


    const CallDataForChart = async (selectedKPICard) => {
        if (dateRange.length >= 1) {
            setLoadingSpinner(true)
            setChartTitle(selectedKPICard);
            const dateRange1 = dateRange[0];
            const dateRange2 = dateRange[1];
            if (dateRange.length >= 1) {
                await axios.post("/kpi/v2/chart/" + _projectID, {
                    DateRange1: [moment(dateRange1).format(), moment(dateRange2).format()],
                    SelectedKPICard: selectedKPICard
                }, config).then(data => {
                    setChartData(data.data)
                    setLoadingSpinner(false)
                })
            }
        }
    }

    function handleDateChange(dates, dateStrings) {
        console.log(dateStrings)
        setDateRange(dateStrings);
    }

    return (
        <>
            {drawerSettingsvisible && <DrawerSettings setVisible={setDrawerSettingsvisible} visible={drawerSettingsvisible} projectID={_projectID}/> }

            {(loadingSpinner || databaseloading) ? (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    {loadingSpinner ? <Spin size="large" tip={`Fetching Values ${'.'.repeat(6)}`} /> : <Spin size="large" tip={`Saving to Database ${'.'.repeat(6)}`} />}
                </div>
            ) : ""}
            <div className="main pos-absolute t-70 r-0 b-0 l-0 l-xl-380 z-index-100 kpi-dashboard">
                <div className="main-header mobile-flex-start pd-x-20 ht-70 d-flex align-items-center justify-content-between pl-0">
                    <h1 className=" mg-b-0 main-heading mg-t-16">
                        <FormattedMessage id="KPIDashboard" defaultMessage="KPI Dashboard" />
                    </h1>
                    <Button className="ml-2" onClick={handleClickLimits}>Set Limits</Button>
                </div>
                <div className='d-flex justify-content-end mg-t-16 mb-3 align-items-center mobile-flex-start'>
                    <div className="responsive-range-picker my-2 ml-3">
                        <RangePicker
                            disabledDate={disabledDate}
                            bordered={true}
                            onChange={handleDateChange}
                            defaultValue={defaultRange}
                            value={[moment(dateRange[0]), moment(dateRange[1])]}
                            clearIcon={false} />
                    </div>
                    <div className='col-md-2 text-right'>
                        <KPIButton projectID={props.projectID} />
                    </div>
                </div>

                <div className="container-fluid plr-0">
                    <div className="card rounded-2 border-gray border-radius-16  kpi-progress-cards">
                        <div className='col-md-12'>
                            {
                                (chartTitle !== "" && (chartTitle === "Average Repair" || chartTitle === "Total Sales" || chartTitle === "Parts Sales" || chartTitle === "Average Consumables" || chartTitle === "Average Paint Cost" || chartTitle === "Average Sublet Sales" || chartTitle === "Average Sublet Cost"))
                                    ? (
                                       <KPIChartSales ChartData={ChartData || null} label={ChartData.meta || { Date: "Date", Count: "Count" }} title={chartTitle !== "" ? chartTitle + " -" : ""} currency={props.currencyName} />
                                    )
                                    : (
                                        (chartTitle === "Key to Key" || chartTitle === "Average Througput")
                                            ? (
                                                <KPIChart ChartData={ChartData || null} label={ChartData.meta || { Date: "Date", Count: "Count" }} title={chartTitle !== "" ? chartTitle + " -" : ""} currency={props.currencyName} />
                                            )
                                            : (
                                                <KPIChartPercentage ChartData={ChartData || null} label={ChartData.meta || { Date: "Date", Count: "Count" }} title={chartTitle !== "" ? chartTitle + " -" : ""} currency={props.currencyName} />
                                            )
                                    )
                            }
                        </div>
                        <Divider />

                        <div className='kpi-v2-row pd-16'>
                            <div className='kpi-single'>
                                <Gauge
                                    title="Total Sales"
                                    targetValue={kpiDataLimits.TotalSales}
                                    onClick={() => {
                                        CallDataForChart("Total Sales");
                                    }}
                                    sign="currency"
                                    targetCurrencyValue={currencyNumberFormat(kpiDataLimits.TotalSales)}
                                    currencyValue={currencyNumberFormat(kpisCalculations.TotalSales)}
                                    value={kpisCalculations.TotalSales}
                                />
                            </div>
                            <div className='kpi-single'>
                                <Gauge
                                    title="Key To Key"
                                    targetValue={kpiDataLimits.KeyToKeyDays}
                                    onClick={() => {
                                        CallDataForChart("Key To Key");
                                    }}
                                    value={kpisCalculations.KeyToKey}
                                />
                            </div>
                            <div className='kpi-single'>
                                <Gauge
                                    title="Average Jobs"
                                    targetValue={kpiDataLimits.Throughput}
                                    onClick={() => {
                                        CallDataForChart("Average Througput");
                                    }}
                                    value={parseFloat(kpisCalculations.ThroughPut).toFixed(1)}
                                />
                            </div>
                            <div className='kpi-single'>
                                <Gauge
                                    title="Parts Sales"
                                    targetValue={kpiDataLimits.PartsSales}
                                    targetCurrencyValue={currencyNumberFormat(kpiDataLimits.PartsSales)}
                                    sign="currency"
                                    onClick={() => {
                                        CallDataForChart("Parts Sales");
                                    }}
                                    currencyValue={currencyNumberFormat(kpisCalculations.PartsSales)}
                                    value={kpisCalculations.PartsSales}
                                />
                            </div>
                            <div className='kpi-single'>
                                <Gauge
                                    title="Parts Profit %"
                                    targetValue={kpiDataLimits.PartsProfit}
                                    onClick={() => {
                                        CallDataForChart("Parts Profit");
                                    }}
                                    sign="%"
                                    value={kpisCalculations.PartsProfit}
                                />
                            </div>
                            <div className='kpi-single last-col'>
                                <Gauge
                                    title="Average Paint Cost % per Job"
                                    targetValue={kpiDataLimits.PaintCost}
                                    onClick={() => {
                                        CallDataForChart("Average Paint Cost Percentage");
                                    }}
                                    sign="%"
                                    value={kpisCalculations.AveragePaintCostPercentage}
                                />
                            </div>
                        </div>

                        <div className='row kpi-v2-row2 pd-16'>
                        <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average $ repair cost"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Repair");
                                    }}
                                    cardvalue={currencyNumberFormat(kpisCalculations.AverageRepair)}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average $ of Consumables per Job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Consumables");
                                    }}
                                    cardvalue={currencyNumberFormat(kpisCalculations.AverageCosumables)}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average Consumbales % per job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Cosumables Percentage");
                                    }}
                                    cardvalue={kpisCalculations.AverageCosumablesPercentage}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average $ of Paint Cost per Job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Paint Cost");
                                    }}
                                    cardvalue={currencyNumberFormat(kpisCalculations.AveragePaintCost)}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average $ of Sublets Sales per Job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Sublet Sales");
                                    }}
                                    cardvalue={currencyNumberFormat(kpisCalculations.AverageSubletSales)}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average Sublets Sales % per Job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Sublet Sales Percentage");
                                    }}
                                    cardvalue={kpisCalculations.AverageSubletSalesPercentage}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average $ of Sublets Cost per Job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Sublet Cost");
                                    }}
                                    cardvalue={currencyNumberFormat(kpisCalculations.AverageSubletCost)}
                                />
                            </div>
                            <div className="col-md-3">
                                <KPICards
                                    cardclass="gray-card"
                                    activeClassName="active"
                                    cardname="Average Sublets Cost % per Job"
                                    cardpercentage=""
                                    onClick={() => {
                                        CallDataForChart("Average Sublet Cost Percentage");
                                    }}
                                    cardvalue={kpisCalculations.AverageSubletCostPercentage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KPIDashboard